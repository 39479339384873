@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300&family=DM+Mono&family=Shrikhand&family=Work+Sans&display=swap');

.not-found{
  background-color: #8f8d8d51;
  height: 60vh;
  width: 60%;
  margin: auto;
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  font-size: 1.5em;
  border-radius: 6px;
  color: #556202;
}

.gohome{
  font-family: 'Work Sans', sans-serif;
  border-radius: 3px;
  font-size: 1em;
  background-color: #acc500;
  border: transparent;
}

.gohome:hover{
  color: white;
  cursor: pointer;
}

.backToTop {
  bottom: 25px;
  right: 25px;
  position: fixed;
  font-size: 80px;
  color: #ffffff;
  cursor: pointer;
  border: none;
  border-radius: 55px;
  -webkit-border-radius: 55px;
  -moz-border-radius: 55px;
  -ms-border-radius: 55px;
  -o-border-radius: 55px;
  background: rgb(16, 12, 45);
}
.backToTop:hover {
  background: #c98436;
}

.App{
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
}

body {
  margin: 0;
  min-height: 100vh;
}

/* Nav */

.navbar{
  padding-left: 2vw;
  font-family: 'Barlow', sans-serif;
  z-index: 1000;
  max-width:100%;
  min-height:50px;
  text-align:center;
  vertical-align:middle;
  top:0px;
  display:flex;
  margin-bottom: 5vh;
}

.nav-link{
  color: #3e3e3e;
  text-decoration: none;
  font-size: 1.5em;
  font-weight: 500;
  display: inline-block;
  width: 80px;
  margin:1em;
  transition: 0.5s;
  padding: 12px;
  line-height: 1.5em;
}

.nav-link:hover{
  background-color: rgba(128, 128, 128, 0.361);
  padding: 12px;
  border-radius: 25px;
}

.name{
  max-width: 250px;
  height: 100%;
  padding: 10px;
}

.name:hover{
  cursor: pointer;
}

/* Links */

.linkbar{
  white-space: nowrap;
  bottom: 0;
  max-width: 100%;
  text-align:center;
  vertical-align:middle;
  color: rgb(118, 118, 118);
}

.link{
  font-size: 18px;
  font-family: 'DM Mono', monospace;
  color: #FFBF9B;
  text-decoration: none;
  display: inline-block;
  margin-left:3em;
  padding: 10px;
}

.copyright {
  font-family: 'Barlow', sans-serif;
  text-align: center;
}

/* Homepage */

.homepage {
  font-family: 'Barlow', sans-serif;
  padding: 2.5em;
  min-height: 100vh;
  margin: auto;
}

.circuit1{
  top: 40%;
  left: 10%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: -3000;
  max-width: 350px;
}

.circuit2{
  top: 80%;
  left: 88.5%;
  transform: translate(-50%, -50%) rotate(180deg);
  position: absolute;
  z-index: -3000;
  max-width: 350px;
}

.circuit3{
  top: 160%;
  left: 90%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: -3000;
  max-width: 150px;
}

.top-container{
  margin-top: 50px;
  padding-bottom: 100px;
}

.self{
  resize: none;
  margin: auto;
  min-width:24vw;
  min-height: 44vh;
  float:right;
  max-width: 300px;
  border-radius: 6px;
  box-shadow: 20px 16px #acc500;
}

.text{
  min-width:48%;
  max-width: 300px;
  float: left;
  height: 20vh;
  padding: 40px;
  margin-top: 70px;
}

.fsd{
  color: #acc500;
  font-size: 40px;
  font-weight:bold;
}

.tagline{
  font-size: 30px;
}

.sub-tagline{
  padding-top: 35px;
  font-size: 18px;
}

.spotlight{
  top: 110%;
  left: 18%;
  transform: translate(-50%, -50%) rotate(-10deg);
  position: absolute;
  z-index: 3000;
  max-width: 250px;
}

.arrow{
  top: 130%;
  left: 20%;
  transform: translate(-50%, -50%) rotate(270deg) scaleX(-1);
  position: absolute;
  z-index: 3000;
  max-width: 150px;
}


.bottom-container{
  margin-top: 70vh;
  padding: 10px;
  justify-content: center;
  flex-direction: row;
  display: flex;
  width: 100%;
}

.card{
  font-family: 'Work Sans', sans-serif;
  padding-bottom:10px;
  text-align: center;
  margin: 20px;
  max-height: 100%;
}

.card-label{
  font-size: 14px;
  line-height: 3;
}

.card-media{
  margin:auto;
  max-width: 290px;
}

.card-content{
  font-family: 'Work Sans', sans-serif; 
}

.resume-container{
  min-height: 100vh;
}

.resume{
  min-height: 100vh;
}

.bio{
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  text-align: center;
  background-color: rgb(54, 52, 53);
  padding-bottom: 10vh;
}

.bio2{
  padding-bottom: 60px;
  text-align: center;
  background-image: linear-gradient(rgb(54, 52, 53), rgb(177, 202, 176), white);
}

.about{
  min-height: 100vh;
}

.current{
  font-size: 19px;
}

.gg{
  margin-left: 400px;
  margin-top: 40px;
  width: 400px;
  border: 30px solid white;
  border-bottom: 105px solid white;
  transform: rotate(4deg);
}

.gg2{
  margin-top: 70px;
  width: 250px;
  border: 20px solid white;
  border-bottom: 85px solid white;
  transform: rotate(-3deg);
  margin-right: 20px;
  box-shadow: 10px 6px 10px -2px rgba(44, 44, 44, 0.548);
}

.gg3{
  width: 250px;
  border: 20px solid white;
  border-bottom: 85px solid white;
  transform: rotate(4deg);
  margin-right: 15px;
  box-shadow: -10px 6px 10px -2px rgba(44, 44, 44, 0.548);

}

.gg4{
  width: 250px;
  border: 20px solid white;
  border-bottom: 85px solid white;
  transform: rotate(2deg);
  box-shadow: -10px  6px 10px -2px rgba(44, 44, 44, 0.5);
}

.gg5{
  margin-top: 70px;
  width: 250px;
  border: 20px solid white;
  border-bottom: 85px solid white;
  transform: rotate(2deg);
  box-shadow: -10px  6px 10px -2px rgba(44, 44, 44, 0.5);
}

.gg6{
  margin-left: 50px;
  width: 450px;
  height: 320px;
  border: 20px solid white;
  border-bottom: 85px solid white;
  transform: rotate(-2deg);
  box-shadow: -10px  6px 10px -2px rgba(44, 44, 44, 0.5);
}

.gg7{
  margin-top: 70px;
  width: 450px;
  height: 365px;
  border: 20px solid white;
  border-bottom: 85px solid white;
  transform: rotate(3deg);
  box-shadow: -10px  6px 10px -2px rgba(44, 44, 44, 0.5);
}

.gg8{
  margin-left: 30px;
  width: 250px;
  border: 20px solid white;
  border-bottom: 85px solid white;
  transform: rotate(-2deg);
  box-shadow: -10px  6px 10px -2px rgba(44, 44, 44, 0.5);
}

.gg9{
  margin-top: 50px;
  width: 250px;
  border: 20px solid white;
  border-bottom: 85px solid white;
  transform: rotate(2deg);
  box-shadow: -10px  6px 10px -2px rgba(44, 44, 44, 0.5);
}

.gg10{
  margin-top: 70px;
  margin-left: 50px;
  width: 420px;
  height: 320px;
  border: 20px solid white;
  border-bottom: 85px solid white;
  transform: rotate(-2deg);
  box-shadow: -10px  6px 10px -2px rgba(44, 44, 44, 0.5);
}

.hello{
  top: 52%;
  left: 32%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 3000;
  max-width: 150px;
}

.imgg{
  top: 63%;
  left: 32%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 3000;
  max-width: 200px;
}

.smiley{
  font-size: 85px;
  top: 73%;
  left: 32%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 3000;
  max-width: 200px;
  color:rgb(54, 52, 53)
}

.bio-div{
  backdrop-filter: blur(3px);
  border-radius: 8px;
  line-height: 1.5;
  font-size: 25px;
  font-family: 'Barlow', sans-serif;
  margin: auto;
  margin-top: 70px;
  padding: 15px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.534);
  width: 70%;
}

.dots{
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  color:rgba(54, 52, 53, 0.564);
  letter-spacing: 5px;
}

.technical-skills{
  margin-top: 50px;
  font-family: 'Barlow', sans-serif;
  color: white;
  letter-spacing: 1.5px;
}

.tech-logo-div{
  margin-left: 70px;
  margin-right: 70px;
  background-color: rgba(255, 255, 255, 0.534);
  border-radius: 8px;
}

.tech-logos{
  box-shadow: 0 0 5px 5px #3333338e;
  border-radius: 6px;
  margin: 20px;
  display: inline-block;
  width: 100px;
  height: 100px;
}

.about-img1{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 2000;
  max-width: 200px;
  border-radius: 100px;
}

.img-circle{
  width: 400px;
  top: 63%;
  left: 32%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1000;
}

.design{
  max-width: 100%;
}

.contact{
  min-height:100vh;
  background-color: #efe7dd;
}

.connect{
  width: 100%;
  text-align: center;
}

.con-1{
  margin-top: 50px;
  width: 100%;
}

.connect-links{
  margin-top: 10vh;
  text-align: center;
  min-width: 100%;
  font-family: 'Work Sans', sans-serif;
  font-size: 20px;
  color:rgb(54, 52, 53);
}

.connect-link1{
  padding: 20px;
  margin: 30px;
  background-color: #ebac66;
  border-radius: 6px;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.connect-link1:hover {
  box-shadow: inset 140px 0 0 0 #464646;
  color: white;
  cursor: pointer;
}

.connect-link2{
  padding: 20px;
  margin: 30px;
  background-color: #ebac66;
  border-radius: 6px;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.connect-link2:hover {
  box-shadow: inset 145px 0 0 0 #464646;
  color: white;
  cursor: pointer;
}

.connect-link3{
  padding: 20px;
  margin: 30px;
  background-color: #ebac66;
  border-radius: 6px;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.connect-link3:hover {
  box-shadow: inset 145px 0 0 0 #464646;
  color: white;
  cursor: pointer;
}

.design-con{
  margin-top: 15vh;
  width: 100%;
}

/* Projects page */

.projects-container{
  font-family: 'Work Sans', sans-serif;
  min-height: 100vh;
  text-align: center;
}

.portfolio-header{
  text-align: center;
}

.portfolio-title{
  max-width: 80%;
  border-radius: 6px;
  padding-bottom: 5px;
}

.project-card{
  text-align: center;
  border: 3px solid rgba(206, 206, 206, 0.326);
  border-radius: 6px;
  height: fit-content;
  background-color: #efe7dd98;
  margin-bottom: 50px;
}

.proj-header{
  height: 50px;
  min-width: 100%;
  text-align: center;
  vertical-align: center;
  margin-bottom: 15px; 
  color:#282828;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.proj-name{
  font-size: 30px;
  float:left;
  padding:5px;
  font-weight: bold;
  color:#4a4949;
}

.proj-timeframe{
  padding-right: 10px;
  font-size: 18px;
  float: right;
  line-height: 2.5;
}

.proj-technologies{
  display: block;
  border-radius: 6px;
  font-size: 15px;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.345);
}

.proj-main{
  padding-bottom: 5px;
}

.proj-features{
  text-align: left;
}

.proj-left{
  height: 60vh;
  width: 50%;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.345);
}

.proj-img{
  max-width: 100%;
  padding-bottom: 40px;
}

::-webkit-scrollbar {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(161, 161, 161);
  border-radius: 10px;
}

.proj-right{
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 50%;
  display: inline-block;
}

.proj-desc{
  padding-top: 10px;
}

.visit{
  font-weight: bold;
  display: block;
  border-radius: 6px;
  font-size: 15px;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.345);
}

.visit:hover{
  cursor: pointer;
  background-color: grey;
  color: white;
}